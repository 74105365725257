<script>
import { layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
import _ from "lodash";

export default {
  components: { SimpleBar },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      menuList: [
        {
          router: "/homePage",
          key: "homePage",
          name: this.$t("menu.home"),
          icon: "ri-home-4-fill",
          permission: null,
          visible: true,
        },
        {
          router: "/garment",
          key: "garment",
          name: this.$t("menu.garment"),
          icon: "ri-shirt-fill",
          permission: "garment",
          visible: false,
        },
        {
          router: "/fabric",
          key: "fabric",
          name: this.$t("menu.fabric"),
          icon: "ri-map-fill",
          permission: "fabric",
          visible: false,
        },
        {
          router: "/yarn",
          key: "yarn",
          name: this.$t("menu.yarn"),
          icon: "ri-route-fill",
          permission: "yarn",
          visible: false,
        },
        {
          router: "/fiber",
          key: "fiber",
          name: this.$t("menu.fiber"),
          icon: "ri-reactjs-line",
          permission: "fibre",
          visible: false,
        },
        {
          router: "/hometextile",
          key: "hometextile",
          name: this.$t("menu.hometextile"),
          icon: "ri-hotel-bed-fill",
          visible: false,
          children: [
            {
              router: "/textile_bedware",
              key: "textile_bedware",
              name: this.$t("menu.textile_bedware"),
              icon: "ri-hotel-bed-fill",
              permission: "textile_bedware",
              visible: false,
            },
            {
              router: "/textile_home_decor",
              key: "textile_home_decor",
              name: this.$t("menu.textile_home_decor"),
              icon: "ri-hotel-bed-fill",
              permission: "textile_home_decor",
              visible: false,
            },
          ]
        },
        {
          router: "/textile",
          key: "textile",
          name: this.$t("menu.textile"),
          icon: "ri-scissors-cut-fill",
          visible: false,
          children: [
            {
              router: "/textile_industrial",
              key: "textile_industrial",
              name: this.$t("menu.textile_industrial"),
              permission: "textile_industrial",
              visible: false,
            },
            {
              router: "/textile_kitchen",
              key: "textile_kitchen",
              name: this.$t("menu.textile_kitchen"),
              permission: "textile_kitchen",
              visible: false,
            },
          ]
        },
        {
          router: "/",
          key: "system_management",
          name: this.$t("menu.system_management"),
          icon: "ri-layout-grid-line",
          permission: "permission",
          visible: false,
          children: [
            {
              router: "/role",
              name: this.$t("menu.role"),
              permission: "role",
              visible: false,
            },
            // {
            //   router: "/user",
            //   name: this.$t("menu.user"),
            //   permission: "user",
            //   visible: false,
            // },
            {
              router: "/treeUser",
              name: this.$t("menu.user"),
              permission: "user",
              visible: false,
            },
            {
              router: "/permission",
              name: this.$t("menu.permission"),
              permission: "permission.role-permission-list",
              visible: false,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.setMenu();
  },
  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length >= 0 && item.firstElementChild) {
                item.firstElementChild.setAttribute("aria-expanded", "false");
              }
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length >= 0) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    setMenu() {
      let storeData =
        this.$store.state.login.menuList ||
        sessionStorage.getItem("set_menu_list");
      let permissions = null;
      if (storeData) {
        permissions = JSON.parse(storeData).permissions;
        permissions = _.join(permissions, ",");
      }
      this.menuList.forEach((menu) => {
        if (menu.children) {
          menu.children.forEach((child) => {
            if (permissions && _.includes(permissions, child.permission)) {
              child.visible = true;
            }
          });
        } else {
          if (permissions && _.includes(permissions, menu.permission)) {
            menu.visible = true;
          }
        }
      });
      this.menuList.forEach((menu) => {
        if (menu.children) {
          menu.children.forEach((child) => {
            if (child.visible) {
              menu.visible = true;
            }
          });
        }
      });

      // console.log(permissions,this.menuList)
    },
    onRoutechange(ele) {
      console.log(this.$route.query.data_type)
      const data_type = this.$route.query.data_type;
      if (['textile_home_decor', 'textile_bedware', 'textile_industrial', 'textile_kitchen'].includes(data_type)) {
        this.initActiveMenu(`/${data_type}`)
      } else {
        this.initActiveMenu(ele.path);
      }
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    },
    initActiveMenu(ele) {
      console.log(ele)
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="#' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 0);
    },
    handlerJump(path, index) {
      this.menu_toggle = index;
      this.$router.push(path);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarDashboards"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDashboards"
          >
            <i class="ri-layout-grid-line"></i>
            <span data-key="t-dashboards">YSR MES SYSTEM</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li
                class="nav-item"
                v-for="(nav, index) in menuList"
                :key="index"
              >
                <router-link
                  :to="nav.router"
                  class="nav-link custom-abc"
                  data-key="t-analytics"
                  >{{ nav.name }}</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <!-- end Dashboard Menu -->
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="nav-item" v-for="(nav, index) in menuList" :key="index">
          <template v-if="nav.children">
            <a
              class="nav-link menu-link"
              :href="`#${nav.key}`"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="sidebarDashboards"
              v-if="nav.visible"
            >
              <i :class="nav.icon"></i>
              <span :data-key="nav.key">{{ nav.name }}</span>
            </a>
            <div class="collapse menu-dropdown" :id="nav.key">
              <ul class="nav nav-sm flex-column" v-if="nav.children">
                <li
                  class="nav-item"
                  v-for="(child, i) in nav.children"
                  :key="i"
                >
                  <router-link
                    v-if="child.visible"
                    :to="child.router"
                    class="nav-link custom-abc"
                    :data-key="nav.key"
                    >{{ child.name }}</router-link
                  >
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <a
              v-if="nav.visible"
              :class="[
                'nav-link menu-link',
                ($route.path == nav.router ||
                  $route.path == nav.router + '_detail/') &&
                  'active',
              ]"
              :href="`#${nav.key}`"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="sidebarDashboards"
              @click="handlerJump(nav.router, index)"
            >
              <i :class="nav.icon"></i>
              <span :data-key="nav.key">{{ nav.name }}</span>
              <!-- <span>{{$route.path}}-{{nav.router}}</span> -->
            </a>
          </template>
        </li>
        <!-- end Dashboard Menu -->
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>

<style scoped>
.container-fluid {
  flex: 1;
}
</style>
