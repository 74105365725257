export const state = {
    currentModelId: null,
};

export const getters = {
};

export const mutations = {
    setCurrentModelId(state, newValue) {
        state.currentModelId = newValue
    },
};

export const actions = {
};
