<template>
  <div
    :class="[
      'alert alert-dismissible alert-solid alert-label-icon fade show position-fixed start-50 translate-middle-x msg-alert',
      `alert-${alertInfo.variant}`,
    ]"
    role="alert"
    :style="alertStyle"
  >
    <i :class="`${alertInfo.icon} label-icon`"></i>
    {{ alertInfo.content }}
    <button
      v-if="showCloseBtn"
      type="button"
      class="btn-close btn-close-white"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="handlerClose"
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    alertInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          variant: "danger",
          content: "表单校验未通过,请检查表单是否填写完整!",
          icon: "ri-error-warning-line",
        };
      },
    },
    alertStyle: {
      type: Object,
      default: () => {},
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    formValidataAlert: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:formValidataAlert"],
  methods: {
    handlerClose() {
      this.$emit("update:formValidataAlert", !this.formValidataAlert);
    },
  },
};
</script>
<style>
.msg-alert {
  min-width: 380px;
  z-index: 9000000;
  top: 20%;
}
</style>
