export const state = {
    menuList: null,
};

export const getters = {
};

export const mutations = {
    set_menu_list(state, menuList) {
        state.menuList = menuList
    }
};

