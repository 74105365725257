import {
	createI18n
} from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// function loadLocaleMessages() {
//   const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   console.log(messages)
//   return messages;
// }

import en from './i18n/lang/en.js'
import ch from './i18n/lang/ch.js'

const setDateTimeFormats = {
	short: {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	},
	long: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
	},
};

const dateTimeFormats = {
	en: setDateTimeFormats,
	es: setDateTimeFormats,
	de: setDateTimeFormats,
	'en-GB': setDateTimeFormats,
};

/**
 * 根据浏览器首选语言设置当前语言
 * */
export function setLanguageByBrowser() {
	let userLang = navigator.language || navigator.userLanguage;
	if (userLang == 'zh-CN' || userLang == 'zh-cn') {
		sessionStorage.setItem('language', 'ch');
	} else {
		sessionStorage.setItem('language', 'en');
	}
}

export function getLanguage() {
	const language = sessionStorage.getItem('language');
	if (language) {
		return language
	}
	return process.env.VUE_APP_I18N_LOCALE || 'ch'
}

export function getAcceptLanguage() {
	let language = sessionStorage.getItem('language');
	if (!language) {
		language = process.env.VUE_APP_I18N_LOCALE || 'ch'
	}

	if (language == 'ch') {
		return 'zh-cn'
	}
	return 'en-us'
}

export function isChinese() {
	const language = getLanguage()
	if (language == 'ch') {
		return true
	}
	return false
}

// function getLocale() {
// 	return process.env.VUE_APP_I18N_LOCALE || 'en'
// }

// function getFallbackLocale() {
// 	return process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
// }

export default createI18n({
	locale: getLanguage(),
	fallbackLocale: getLanguage(),
	messages: {
		en,
		ch
	},
	// messages: loadLocaleMessages,
	dateTimeFormats,
    globalInjection: true
});
