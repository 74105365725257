<template>
    <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
    export default {
        name: 'App',
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true,
            }
        },
        methods: {
            reload() {
                console.log('reload')
                this.isRouterAlive = false
                this.$nextTick(function() {
                    this.isRouterAlive = true
                })
            }
        },
        watch: {
            '$i18n.locale'(newVal, oldVal) {
                if (newVal !== oldVal) {
                    // this.$router.go(0)
                    this.reload();
                }
            }
        }
    };
</script>

<style></style>
