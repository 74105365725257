<template>
    <div class="d-flex justify-content-end align-items-center mt-3" style="padding-right: 10px">
        <div class="page-item page_total">
            {{$t('base.total')}}: <span class="ms-1">{{ tab_info_length }}</span>{{$t('base.total_unit')}}
        </div>
        <div class="page-item page_number">
            <select class="form-select" v-model="prePageNum" @change="perPageChanged()">
                <option :value="option" v-for="(option, i) in prePageOption" :key="i">
                    {{ option }}
                </option>
            </select>
        </div>

        <div class="pagination-wrap hstack gap-2">
            <a class="page-item pagination-prev" href="javascript:;" @click="prev()">
                {{$t('base.previous_page')}}
            </a>
            <ul class="pagination listjs-pagination mb-0">
                <li v-show="page != 1" @click="choosePage(pages[0])">
                    <a class="page" href="javascript:;">{{ pages[0] }}</a>
                </li>
                <li v-show="page != 1 && page != 2">
                    <a class="page" href="javascript:;">...</a>
                </li>
                <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 3)" :key="index"
                    @click="choosePage(pageNumber)" :class="{
            active: pageNumber == page,
            disabled: pageNumber == '...',
          }">
                    <a class="page" href="javascript:;">{{ pageNumber }}</a>
                </li>
                <li v-show="
            page + 3 < pages[pages.length - 2] &&
            page * perPage <= tab_info_length
          ">
                    <a class="page" href="javascript:;">...</a>
                </li>
                <li v-show="
            page + 3 < pages[pages.length - 1] &&
            page * perPage <= tab_info_length
          " @click="choosePage(pages[pages.length - 1])">
                    <a class="page" href="javascript:;">{{ pages[pages.length - 1] }}</a>
                </li>
            </ul>
            <a class="page-item pagination-next" href="javascript:;" @click="next()">
                {{$t('base.next_page')}}
            </a>
            <!-- <div class="text-muted d-flex align-items-center input-group">
                <span class="me-2">{{$t('base.jump_to')}}</span>
                <input class="form-control form-control-sm flex-1" style="width: 50px;" type="text" v-model="jumpPage"
                    @input="jumpPage = jumpPage.replace(/[^0-9]/g, '')" @keyup.enter="jump2Pages()" />
                <span class="me-2" style="margin-left: 5px;">{{$t('base.jump_to_unit')}}</span>
            </div> -->
            <span class="me-2">{{$t('base.jump_to')}}</span>
            <input class="form-control form-control-sm flex-1" style="width: 50px;" type="text" v-model="jumpPage"
                @input="jumpPage = jumpPage.replace(/[^0-9]/g, '')" @keyup.enter="jump2Pages()" />
            <span class="me-2">{{$t('base.jump_to_unit')}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                prePageNum: 0,
                jumpPage: "",
            };
        },
        emits: ["update:page", "update:perPage"],
        props: {
            pages: {
                type: Array,
                default: () => [],
            },
            page: {
                type: Number,
                default: 1,
            },
            perPage: {
                type: Number,
                default: 10,
            },
            prePageOption: {
                type: Array,
                default: () => [],
            },
            tab_info_length: {
                type: Number,
                default: 0,
            },
        },
        watch: {
            perPage: {
                handler(val) {
                    this.prePageNum = val;
                },
                immediate: true,
            },
        },
        methods: {
            choosePage(val) {
                this.$emit("update:page", val);
            },
            perPageChanged() {
                this.$emit("update:perPage", this.prePageNum);
                console.log("prePage", this.prePageNum);
            },
            next() {
                let pageNext = this.page;
                if (pageNext < this.pages.length) {
                    this.$emit("update:page", pageNext + 1);
                }
            },
            prev() {
                let pagePrev = this.page;
                if (pagePrev != 1) {
                    this.$emit("update:page", pagePrev - 1);
                }
            },
            jump2Pages() {
                if (
                    !this.jumpPage ||
                    (this.jumpPage &&
                        Number(this.jumpPage) > this.pages[this.pages.length - 1])
                ) {
                    return;
                }
                this.$emit("update:page", Number(this.jumpPage));
            },
        },
        components: {},
    };
</script>

<style scoped lang="scss">
    .page_total {
        margin-right: 0.5rem;
        color: rgba(153, 153, 153, 0.99);
    }

    .page_number {
        // padding: 0.375rem 0.75rem;
        background-color: rgba(245, 247, 251, 1);
        color: rgba(153, 153, 153, 0.99);
        margin: 0 1rem;
    }

    .form-select {
        padding: 0.4rem 2.7rem 0.3rem 0.9rem;
    }
</style>
