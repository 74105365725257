<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <img
            src="https://fs.goldendata.cloud/public/footer-logo.png"
            alt=""
            style="width: 160px; height: 32px"
          />
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end">V1.0.0-ALPHA.1</div>
          <div class="text-sm-end">©GoldenData 2022 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer {
  z-index: 1000;
}
</style>
