<template>
  <div class="loading">
    <i class="mdi mdi-spin mdi-loading icon"></i>
    <p class="tip">{{$t('base.loading')}}</p>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.loading {
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    font-size: 1.5rem;
    color: rgba(33, 37, 41, 0.64);
    margin-right: 2px;
  }
  .icon:before {
    animation: mdi-spin 0.8s infinite linear;
  }
  .tip {
    font-size: 1rem;
    font-weight: 500;
    color: rgba(33, 37, 41, 0.64);
    margin-bottom: 0;
  }
}
</style>