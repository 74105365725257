<script>
import router from "@/router";
import { SimpleBar } from "simplebar-vue3";
import { layoutComputed } from "@/state/helpers";

import NavBar from "@/components/nav-bar";
import Menu from "@/components/menu.vue";
import RightBar from "@/components/right-bar";
// import Footer from "@/components/footer";
localStorage.setItem("hoverd", false);

/**
 * Vertical layout
 */
export default {
  components: {
    NavBar,
    RightBar,
    // Footer,
    SimpleBar,
    Menu,
  },
  data() {
    return {
      isMenuCondensed: false,
      showCompany: true,
    };
  },
  computed: {
    ...layoutComputed,
    titleMark() {
      if(process.env.VUE_APP_COMPANY_LOGO_CN && JSON.parse(process.env.VUE_APP_COMPANY_LOGO_CN) ){
        return '2.0 CN'
      } else {
        return '2.0 UK'
      }
    },
    companyList(){
      let company=[]
      if(process.env.VUE_APP_COMPANY_LOGO_CN && JSON.parse(process.env.VUE_APP_COMPANY_LOGO_CN) ){
        company = [
         {
           name: "Digital Clothing Limited.",
           name1: "数字服装有限公司",
           icon: require("@/assets/company1.svg"),
         },
         {
           name: "宁波享励数字科技有限公司",
           icon: require("@/assets/company2.svg"),
         },
       ]
      }else {
        company = [
         {
           name: "Digital Clothing Limited.",
           icon: require("@/assets/company1.svg"),
         }
       ]
      }
      return company
    }
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    initActiveMenu() {
      if (
        document.documentElement.getAttribute("data-sidebar-size") ===
        "sm-hover"
      ) {
        localStorage.setItem("hoverd", true);
        document.documentElement.setAttribute(
          "data-sidebar-size",
          "sm-hover-active"
        );
      } else if (
        document.documentElement.getAttribute("data-sidebar-size") ===
        "sm-hover-active"
      ) {
        localStorage.setItem("hoverd", false);
        document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
      } else {
        document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
      }
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    if (localStorage.getItem("hoverd") == "true") {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    }
    document.getElementById("overlay").addEventListener("click", () => {
      document.body.classList.remove("vertical-sidebar-enable");
    });
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar v-model:showCompany="showCompany" />
    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box mb-3">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/logo.png" alt="" height="55" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/logo.png" alt="" height="22" />
            </span>
            <view class="logo-lg">
              <view class="d-flex justify-content-start align-items-center" style="line-height: normal;padding: 1rem 0;">
                <img src="@/assets/logo.png" alt="" height="55" />
                <view class="text-white bold" style="font-size: 1.4rem;padding-left: 0.5rem;padding-top: 0.35rem;text-wrap: nowrap">
                  {{ titleMark }}</view>
              </view>
            </view>
          </router-link>
          <button
            type="button"
            class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            "
            id="vertical-hover"
            @click="initActiveMenu"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <SimpleBar id="scrollbar" class="h-100" ref="scrollbar">
          <Menu></Menu>
          <div class="menu-company" v-show="showCompany">
            <div
              v-for="(company, index) in companyList"
              :key="index"
              class="company"
            >
              <div class="company-name">
                {{ company.name }}
                <div v-if="company.name1" class="mt-1">{{ company.name1 }}</div>
              </div>
              <div>
                <img :src="company.icon" class="company-icon" />
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay" id="overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div
        :class="[
          'page-content',
          $route.name == 'homePage' && 'page-content-home',
        ]"
      >
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!-- <Footer /> -->
    </div>
    <RightBar />
  </div>
</template>
<style scoped lang="scss">
.page-content {
  padding: calc(70px + 1.5rem) calc(1.5rem / 2) 0px calc(1.5rem / 2);
}
.page-content-home {
  padding: 70px 0 0;
  .container-fluid {
    padding: 0;
  }
}
.menu-company {
  width: 100%;
  margin: 30px 0;
  .company {
    display: flex;
    align-items: center;
    padding: 0 15px;
    &:nth-last-child(1) {
      margin-top: 20px;
    }
    .company-name {
      flex: 1;
      margin-right: 20px;
      color: #ccc;
    }
    .company-icon {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
