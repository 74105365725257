export default {
	base: {
		search: 'Search',
		advance_search: 'Advance Search',
		reset: 'Reset',
		min: 'min',
		max: 'max',
		to: 'to',
		no_pictures: 'No Pictures',
		add: 'Add',
		add1: '+ Add',
		to_view: 'View',
		edit: 'Edit',
		delete: 'Delete',
		operation: 'Actions',
		save: 'Save',
		confirm: 'Confirm',
		done: 'Done',
		cancel: 'Cancel',
		success: 'Success',
		fail: 'Fail',
		delete_alert_role_title: 'Delete failed!',
		delete_alert_role_info: 'This role contains user information. Please delete all users under this role or unbind this role before deleting!',
		delete_alert_title: 'Are you sure?',
		delete_alert_info: "You won't be able to revert this!",
		delete_alert_completed: 'Completed',
		add_success: 'Success',
		edit_success: 'Success',
		search_id: 'Search',
		upload_file_limit: 'Only jpg/png files can be uploaded, and the size cannot exceed 50 MB',
		upload_picture_limit: 'Only jpg/png files can be uploaded, and the size cannot exceed 50 MB',
		upload_excel_limit: 'Only excel files can be uploaded, and the size cannot exceed 50 MB',
		no_data: 'No Data',
		hint_name: 'The name cannot be empty!',
		none: 'None',
		total: 'Total',
		total_unit: '',
		previous_page: 'Previous',
		next_page: 'Next',
		jump_to: 'Go to',
		jump_to_unit: 'Page',
		empty: 'Empty',
		loading: 'Loading...',
		disabled: 'Disabled',
		enabled: 'Enabled',
		request403: 'Permission Denied',
		grant_all_garment: 'Garment editable',
		grant_all_garment_view: 'Garment readonly',
		grant_all_fabric: 'Fabric editable',
		grant_all_fabric_view: 'Fabric readonly',
		grant_all_yarn: 'Yarn editable',
		grant_all_yarn_view: 'Yarn readonly',
		grant_all_fibre: 'Fibre editable',
		grant_all_fibre_view: 'Fibre readonly',
		grant_all_textile_bedware: 'Bedding editable',
		grant_all_textile_bedware_view: 'Bedding readonly',
		grant_all_textile_home_decor: 'Household textiles editable',
		grant_all_textile_home_decor_view: 'Household textiles readonly',
		grant_all_textile_industrial: 'Industrial textiles editable',
		grant_all_textile_industrial_view: 'Industrial textiles readonly',
		grant_all_textile_kitchen: 'Kitchen and bathroom supplies editable',
		grant_all_textile_kitchen_view: 'Kitchen and bathroom supplies readonly',
		english: 'English',
		chinese: 'Chinese',
		preview: 'Preview',
		is_public: 'is public',
		user_info: 'User Info',
		user_center_name: "User Name",
		change_password: "Change Password",
		original_password: "Original Password",
		new_password: 'New Password',
		confirm_password: 'Confirm Password',
		confirm_password_info: 'The passwords entered twice are inconsistent',
		change_success: 'Change Password Success',
		filter_password: 'The new password is consistent with the original password, please modify it again',
		hint_code: 'HSCode is required',
		No: 'No.',
		resetFile: "Uploading a new file will overwrite the original file. Do you want to continue?",
		show: 'Show',
	},
	upload: {
		title: 'Drop files here or click to upload',
		limit: 'Upload file size limit：',
		btn: 'Upload',
	},
	menu: {
		home: 'Home Page',
		garment: 'Garment Database',
		fabric: 'Fabric Database',
		yarn: 'Yarn Database',
		fiber: 'Fibre Database',
		hometextile: 'Hometextile Database',
		textile_bedware: 'Bedding Database',
		textile_home_decor: 'Household Textiles Database',
		textile_industrial: 'Industrial Textiles Database',
		textile_kitchen: 'Kitchen and Bathroom Supplies Database',
		textile: 'Industrial Textiles Database',
		system_management: 'System Management',
		role: 'Role',
		user: 'User',
		permission: 'Permission',
	},
	home: {
		title: 'FBD—Big Data Technology Platform for Textile Supply Chain',
		supplier: "Supplier",
		customers: "Customers",
		products: "Products",
		market: "Market",
		inventory: "Inventory",
		quality: "Regular quality indexes",
		comfort: "Hand/Skin/Wear comfort",
		traceability: "Traceability",
		circularity: "Circularity",
		GHG: "GHG Footprints",
		social: "Social Impact",
	},
	attribute: {
		base: 'Basic Information',
		other: 'Other Information',
		indicat: 'Traceability Indicators',
		value: 'Value',
		unit: 'Unit',
		id: 'ID',
		name: 'Name',
		type: 'Type',
		size: 'Size',
		size1: 'Size',
		brand: 'Brand',
		brand_name: 'Brand Name',
		supplier: 'Supplier',
		cost: 'Cost',
		price: 'Price',
		price_unit: 'euro/piece',
		price_unit1: 'euro/ton',
		stock: 'Inventory',
		stock_unit: 'piece',
		stock_unit1: 'ton',
		style: 'Style',
		fitting: 'Fitting',
		code: 'HSCode',
		structure: 'Structure',
		safety: 'Safety',
		protection: 'Protection',
		features: 'Features',
		others: 'Others',
		composition: 'Composition',
		designer: 'Designer',
		body_coverage_ratio: 'Body Coverage Ratio',
		manufacturer: 'Manufacturer',
		picture: 'Picture',
		water_contact_angle: 'Water Contact Angle',
		water_contact_angle_unit: 'deg',
		percentage: '%',
		number: 'Number',
		diameter: 'Diameter',
		density: 'Density',
		radius: 'Radius',
		phase_change_material_add_on_level: 'Phase Change Material Add-on Level',
		latent_heat_of_fusion: 'Latent Heat Of Fusion',
		latent_heat_of_fusion_unit: 'cal/g',
		melting_point: 'Melting Point',
		thermal_conductivity_of_liquid_pcm: 'Thermal Conductivity Of Liquid PCM',
		thermal_conductivity_of_siquid_pcm: 'Thermal Conductivity Of Solid PCM',
		heat_trnasfer_coefficient: 'Heat Trnasfer Coefficient',
		cm: 'cm',
		density_unit: 'g/m3',
		deg: 'deg',
		thermal_conductivity_unit: 'cal/s/cm/deg',
		heating_load: 'Heating Load',
		heating_load_unit: 'cal/s/cm3',
		switch_on_temperature: 'Switch On Temperature',
		switch_off_temperature: 'Switch Off Temperature',
		switch_off_temperature_unit: 'cal/s/cm3',
		water_vapor_permeability: 'Water Vapour Permeability',
		thermal_resistance: 'Thermal Resistance',
		temperature: 'Temperature',
		membrane_thickness: 'Membrane Thickness',
		membrane_type: 'Membrane Type',
		membrane_location: 'Membrane Location',
		tensile_properties: 'Tensile Properties',
		permeability: 'Air Permeability',
		tensile_stress: 'Tensile Stress(Mpa)',
		tensile_strain: 'Tensile Strain(%)',
		pressure_range: 'Pressure Range(Pa)',
		flow_rate: 'Flow Rate(L/m2/s)',
		feel_skin: 'Hand Feel and Skin Comfort',
		heat_simulation: 'Thermal Comfort - Simulation Setting',
		heat_file: 'Comfort Dat File',
		version_2d: '2D Pattern File',
		fitting_virtual: 'Virtual Fitting',
		fitting_virtual_link: 'Virtual Fitting Link',
		body_virtual: 'Virtual Body Comfort',
		body_virtual_link: 'Virtual Body Comfort Link',
		show_virtual: 'Virtual Display',
		show_virtual_link: 'Virtual Display Link',
		last_time: 'Last Time',
		description: 'Description',
		status: 'Status',
		role: 'Role',
		role_name: 'Role Name',
		parent_role: 'Parent Role',
		user_name: 'User Name',
		password: 'Password',
		organization: 'Organization',
		department: 'Department',
		phone: 'Phone',
		email: 'Email',
		create_time: 'Create Time',
		just_fit: 'Just Fit',
		tight_fit: 'Tight Fit',
		loose_fit: 'Loose Fit',
		comfortable: 'Comfortable Indicators',
		quality: 'Quality Indicators',
		business: 'Business Indicators',
		environment: 'Environmental Indicators',
		shell: 'Shell',
		interlayer: 'Interlayer',
		lining: 'Lining',
		reference: 'Reference Standard',
		indicators_related: 'Indicator Association',
		indicator_name: 'Indicator Name',
		// 请先设置HSCODE
		please_set_hscode: 'Please set HSCode first',
		indicator_related_confirm: 'This operation will modify all indicator displays and reference standards under this item code, do you confirm?',
		search_indicator_name: 'Search Indicator Name...',
	},
	radar_chart: {
		feel: 'Hand Feel',
		skin: 'Skin Comfort',
		skin_discomfort: 'Skin Discomfort',
		heat: 'Thermal Comfort',
		softness: 'Softness',
		stiff: 'Stiff',
		roughness: 'Roughness',
		smoothness: 'Smoothness',
		warmth: 'Warmth',
		total: 'Total',
		prickle: 'Prickle',
		comfort_value: 'Thermal Comfort',
		dampness_sensation: 'Dampness Sensation',
		thermal_sensation: 'Thermal Sensation',
		average: 'Average',
		footprint: 'Carbon Footprint',
		recyclable: 'Recyclable Index',
		composition: 'Composition'
	},
	login: {
		title: 'Welcome Back !',
		subtitle: 'Login and continue to next',
		username: 'User Name',
		password: 'Password',
		remember: 'Remember me',
		login: 'Login',
		hint_username: 'Please enter user name',
		hint_password: 'Please enter password',
		logout_title: 'Do you want to log out?',
	},
	dashboard: {
		name: 'Home Page',
		title: 'Home Page',
	},
	garment: {
		name: 'Garment',
		title: 'Garment Database',
		add: 'Add New Garment',
		edit: 'Edit Garment Profile',
		detail: {
			title: 'Garment Profile',
		},
		version: 'Garment Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
	},
	fabric: {
		name: 'Fabric',
		title: 'Fabric Database',
		add: 'Add New Fabric',
		edit: 'Edit Fabric Profile',
		comfortable_base: "Comfortable BaseInfo",
		detail: {
			title: 'Fabric Profile',
			water: 'Moisture Management Properties',
			membrane: 'Membrane Properties',
			disguised: 'Phase Change Material',
			heat: 'Self Heating',
			ftt: 'FTT',
			liquid_water_conductivity: 'mmt',
			sst: 'Spreading speed at top surface(SST)',
			ssb: 'Spreading speed at bottom surface(SSB)',
			r: 'Accumulative one-way transport capacity(R)',
			ommc: 'Overall moisture management capability(OMMC)',
			top_water_contact_angle: 'Top Water Contact Angle',
			bottom_water_contact_angle: 'Bottom Water Contact Angle',
		},
		no_layers: 'No.of Fabric',
		id: 'Fabric ID',
		structure: 'Fabric Structure',
		structure_layer: 'Fabric Structure Layer',
		color: 'Fabric Color',
		thickness: 'Fabric Thickness ',
		thickness_unit: 'cm',
		weight: 'Fabric Weight',
		weight_unit: 'g/m2',
		density: 'Fabric Density',
		density_unit: 'g/m3',
		tortuous: 'Fabric Tortuous gas',
		porosity: 'Fabric Porosity gas',
		keyword_placeholder: 'ID/Name/Type/Supplier',
		tips: 'Textile Fabric Touch Determination and Evaluation Method: Multi-Index Integration Method'
	},
	yarn: {
		name: 'Yarn',
		title: 'Yarn Database',
		add: 'Add New Yarn',
		edit: 'Edit Yarn Profile',
		detail: {
			title: 'Yarn Profile',
		},
		id: 'Yarn ID',
		percentage: 'Yarn Percentage',
		percentage_unit: '%',
		type: 'Yarn Type',
		linear_density: 'Yarn Linear Density',
		linear_density_unit: 'tex',
		diameter: 'Yarn Diameter',
		diameter_unit: 'mm',
		twist: 'Yarn Twist',
		twist_unit: 'twist/meter',
		keyword_placeholder: 'ID/Type/Supplier/Brand',
	},
	fiber: {
		name: 'Fibre',
		title: 'Fibre Database',
		add: 'Add New Fibre',
		edit: 'Edit Fibre Profile',
		detail: {
			title: 'Fibre Profile',
		},
		id: 'Fibre ID',
		type: 'Fibre Type',
		percentage: 'Fibre Percentage',
		percentage_unit: '%',
		density: 'Fibre Density',
		density_unit: 'g/cm3',
		diameter: 'Fibre Diameter',
		diameter_unit: 'mm',
		thermal_conductivity: 'Fibre Thermal Conductivity',
		thermal_conductivity_unit: 'W/(m*k)',
		moisture_regain: 'Moisture Regain',
		moisture_regain1: 'Regain(%)',
		water_contact_angle: 'Water Contact Angle',
		water_contact_angle_unit: 'deg',
		water_diffusion_coefficient: 'Water Diffusion Coefficient',
		water_diffusion_coefficient_unit: 'm2/s',
		relative_humidity: 'RH(%)',
		keyword_placeholder: 'ID/Type/Supplier/Brand',
	},
	hometextile: {
		name: 'Hometextile',
		title: 'Hometextile Database',
		add: 'Add New Hometextile',
		edit: 'Edit Hometextile Profile',
		detail: {
			title: 'Hometextile Profile',
		},
		version: 'Hometextile Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
	},
	textile: {
		name: 'Industrial Textiles',
		title: 'Industrial Textiles Database',
		add: 'Add New Industrial Textiles',
		edit: 'Edit Industrial Textiles Profile',
		detail: {
			title: 'Industrial Textiles Profile',
		},
		version: 'Industrial Textiles Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
	},
	textile_kitchen: {
		name: 'Kitchen and Bathroom Supplies',
		title: 'Kitchen and Bathroom Supplies List',
		add: 'Add Kitchen and Bathroom Supplies',
		edit: 'Edit Kitchen and Bathroom Supplies',
		detail: {
			title: 'Kitchen and Bathroom Supplies Profile',
		},
		version: 'Kitchen and Bathroom Supplies Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
		parent: 'Industrial Textiles Patterns',
	},
	textile_bedware: {
		name: 'Bedding',
		title: 'Bedding List',
		add: 'Add Bedding',
		edit: 'Edit Bedding',
		detail: {
			title: 'Bedding Profile',
		},
		version: 'Bedding Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
		parent: 'Hometextile Database',
	},
	textile_home_decor: {
		name: 'Household Textiles',
		title: 'Household Textiles List',
		add: 'Add Household Textiles',
		edit: 'Edit Household Textiles',
		detail: {
			title: 'Household Textiles Profile',
		},
		version: 'Household Textiles Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
		parent: 'Hometextile Database',
	},
	textile_industrial: {
		name: 'Industrial Textiles',
		title: 'Industrial Textiles List',
		add: 'Add Industrial Textiles',
		edit: 'Edit Industrial Textiles',
		detail: {
			title: 'Industrial Textiles Profile',
		},
		version: 'Industrial Textiles Patterns',
		keyword_placeholder: 'ID/Name/Type/Supplier/Brand',
		parent: 'Industrial Textiles Patterns',
	},
	role: {
		name: 'Role',
		title: 'Role List',
		add: 'Add New Role',
		edit: 'Edit Role',
		detail: {
			title: 'Role Information',
		},
		keyword_placeholder: 'Role Name',
		hint_role_name: 'The role name cannot be empty!',
		emptyRole: 'Please create a role first'
	},
	user: {
		name: 'User',
		title: 'User List',
		add: 'Add New User',
		edit: 'Edit User',
		detail: {
			title: 'User Information',
		},
		keyword_placeholder: 'User Name',
		hint_name: 'The name cannot be empty!',
		hint_user_name: 'The username cannot be empty!',
		hint_password: 'The password cannot be empty!',
		hint_role: 'The role cannot be empty!',
	},
	permission: {
		name: 'Permission',
		title: 'Permission',
		role: 'Role List',
		permission: 'Permission',
	},
	smooth: 'Smooth',
	rough: 'Rough',
	soft: 'Soft',
	stiff: 'Stiff',
	prickle: 'Prickle',
	cool: 'Cool',
	warm: 'Warm',
	comfortable: 'Comfortable',
	uncomfortable: 'Uncomfortable',
	discomfort: 'Discomfort',
	dry: 'Dry',
	damp: 'Damp',
	garmentProfile: 'Garment Profile',
	fabricProfile: 'Fabric Profile',
	yarnProfile: 'Yarn Profile',
	fibreProfile: 'Fibre Profile',
	homeTextileProfile: 'Home Textile Profile',
	industrialTextileProfile: 'Industrial Textile Profile',
}
