// import store from "@/state/store";

export default [
	{
	path: '/',
	redirect: "/login",
},
{
	path: "/login",
	name: "login",
	component: () => import("../views/login/login"),
},
/* 首页 */
{
	path: "/dashboard",
	name: "dashboard",
	// redirect: "/login",
	meta: {
		title: "dashboard",
		authRequired: true,
	},
	component: () => import("../views/dashboard/index"),
},
/* 主页 */
{
	path: "/homePage",
	name: "homePage",
	meta: {
		title: "homePage",
		authRequired: true,
	},
	component: () => import("../views/homePage/index.vue"),
},
/* 用户信息 */
{
	path: "/user_info",
	name: "user_info",
	// redirect: "/login",
	meta: {
		title: "user_info",
		authRequired: true,
	},
	component: () => import("../components/userInfo.vue"),
},
/* 服装 */
{
	path: "/garment",
	name: "garment",
	meta: {
		title: "garment",
		authRequired: true,
	},
	component: () => import("../views/garment/index"),
},
{
	path: "/garment_detail",
	name: "garment_detail",
	meta: {
		title: "garment_detail",
		authRequired: true,
	},
	component: () => import("../views/garment/Detail.vue"),
},
/* 织物 */
{
	path: "/fabric",
	name: "fabric",
	meta: {
		title: "fabric",
		authRequired: true,
	},
	component: () => import("../views/fabric/index"),
},
{
	path: "/fabric_detail",
	name: "fabric_detail",
	meta: {
		title: "fabric_detail",
		authRequired: true,
	},
	component: () => import("../views/fabric/Detail.vue"),
},
/* 纱线 */
{
	path: "/yarn",
	name: "yarn",
	meta: {
		title: "yarn",
		authRequired: true,
	},
	component: () => import("../views/yarn/index"),
},
{
	path: "/yarn_detail",
	name: "yarn_detail",
	meta: {
		title: "yarn_detail",
		authRequired: true,
	},
	component: () => import("../views/yarn/Detail.vue"),
},
/* 纤维 */
{
	path: "/fiber",
	name: "fiber",
	meta: {
		title: "fiber",
		authRequired: true,
	},
	component: () => import("../views/fiber/index"),
},
{
	path: "/fiber_detail",
	name: "fiber_detail",
	meta: {
		title: "fiber_detail",
		authRequired: true,
	},
	component: () => import("../views/fiber/Detail.vue"),
},
/* 家纺 */
{
	path: "/textile_bedware",
	name: "textile_bedware",
	meta: {
		title: "textile_bedware",
		authRequired: true,
	},
	component: () => import("../views/textile/index"),
},
{
	path: "/textile_home_decor",
	name: "textile_home_decor",
	meta: {
		title: "textile_home_decor",
		authRequired: true,
	},
	component: () => import("../views/textile/index"),
},
{
	path: "/textile_industrial",
	name: "textile_industrial",
	meta: {
		title: "textile_industrial",
		authRequired: true,
	},
	component: () => import("../views/textile/index"),
},
{
	path: "/textile_kitchen",
	name: "textile_kitchen",
	meta: {
		title: "textile_kitchen",
		authRequired: true,
	},
	component: () => import("../views/textile/index"),
},
// {
// 	path: "/homeTextile_detail",
// 	name: "homeTextile_detail",
// 	meta: {
// 		title: "homeTextile_detail",
// 		authRequired: true,
// 	},
// 	component: () => import("../views/homeTextile/Detail.vue"),
// },
/* 纺织品 */
// {
// 	path: "/textile",
// 	name: "textile",
// 	meta: {
// 		title: "textile",
// 		authRequired: true,
// 	},
// 	component: () => import("../views/textile/index"),
// },
{
	path: "/textile_detail",
	name: "textile_detail",
	meta: {
		title: "textile_detail",
		authRequired: true,
	},
	component: () => import("../views/textile/Detail.vue"),
},
/**
 * 报告
 */
{
	path: "/report/:type/:id",
	name: "report",
	meta: {
		title: "report",
		authRequired: true,
	},
	component: () => import("../views/report/index.vue"),
},
/**
 * 系统管理
 */
{
	path: "/system_management",
	name: "system_management",
	meta: {
		title: "system_management",
		authRequired: true,
	},
	component: () => import("@/views/system_management/index"),
},
{
	path: "/role",
	name: "role_management",
	meta: {
		title: "role_management",
		authRequired: true,
	},
	component: () => import("@/views/system_management/role/index"),
},
{
	path: "/user",
	name: "user_management",
	meta: {
		title: "user_management",
		authRequired: true,
	},
	component: () => import("@/views/system_management/user/index"),
},
// 树状结构
{
	path: "/treeUser",
	name: "treeUser_management",
	meta: {
		title: "treeUser_management",
		authRequired: true,
	},
	component: () => import("@/views/system_management/treeUser/index"),
},
{
	path: "/permission",
	name: "permission_management",
	meta: {
		title: "permission_management",
		authRequired: true,
	},
	component: () => import("@/views/system_management/permission/index"),
},
	{
		path: "/public-garment/:id",
		name: "public-garment",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/garment/Detail"),
	},
	{
		path: "/public-fabric/:id",
		name: "public-fabric",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/fabric/Detail"),
	},
	{
		path: "/public-yarn/:id",
		name: "public-yarn",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/yarn/Detail"),
	},
	{
		path: "/public-fibre/:id",
		name: "public-fibre",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/fibre/Detail"),
	},
	{
		path: "/public-hometextile/:id",
		name: "public-hometextile",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/homeTextile/Detail"),
	},
	{
		path: "/public-textile/:id/:data_type",
		name: "public-textile",
		meta: {
			authRequired: false,
		},
		component: () => import("@/views/mobile/textile/Detail"),
	},
];
