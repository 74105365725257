<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <button
            type="button"
            class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            "
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- 用户信息-->
          <div class="d-flex align-items-center">
            <div style="margin-right: 20px">
              <a href="javascript:;" @click="chineseClick()">{{ chinese }}</a>
              <span style="padding: 0px 10px">|</span>
              <a href="javascript:;" @click="englishClick()">{{ english }}</a>
            </div>
            <div class="d-flex align-items-center me-3">
              <i
                class="ri-user-2-fill me-2"
                style="font-size: 1.5rem; color: rgb(10, 179, 156)"
              ></i>
              <span
                style="font-size: 1rem; cursor: pointer"
                @click="handlerJumpUser()"
                >{{ user_name }}</span
              >
            </div>
            <i
              class="ri-shut-down-line"
              style="font-size: 1.5rem; cursor: pointer"
              @click="logout()"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Swal from "sweetalert2";
import i18n from "../i18n";

/**
 * Nav-bar Component
 */
export default {
  inject: ["reload"],
  props: {
    showCompany: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:showCompany"],
  data() {
    return {
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      user_name: "",
      toggles: true,
    };
  },
  watch: {
    showCompany: {
      handler(val) {
        this.toggles = val;
      },
      immediate: true,
    },
  },
  created() {
    this.user_name =
      sessionStorage.getItem("set_menu_list") &&
      JSON.parse(sessionStorage.getItem("set_menu_list")).username;
  },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

    // this.isCustomDropdown();
  },
  methods: {
    // isCustomDropdown() {
    //   //Search bar
    //   var searchOptions = document.getElementById("search-close-options");
    //   var dropdown = document.getElementById("search-dropdown");

    //   document.body.addEventListener("click", (e) => {
    //     if (e.target.getAttribute("id") !== "search-options") {
    //       dropdown.classList.remove("show");
    //       searchOptions.classList.add("d-none");
    //     }
    //   });
    // },
    handlerJumpUser() {
      this.$router.push("/user_info");
    },
    toggleHamburgerMenu() {
      this.$emit("update:showCompany", !this.toggles);
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
    logout() {
      Swal.fire({
        title: this.$t("login.logout_title"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        cancelButtonText: this.$t("base.cancel"),
        confirmButtonColor: "#34c38f",
        confirmButtonText: this.$t("base.done"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("/login");
          sessionStorage.removeItem("is_login");
          sessionStorage.removeItem("set_menu_list");
          // sessionStorage.removeItem('language');
        }
      });
    },
    chineseClick() {
      const language = "ch";
      sessionStorage.setItem("language", language);
      i18n.global.locale = language;
    },
    englishClick() {
      const language = "en";
      sessionStorage.setItem("language", language);
      i18n.global.locale = language;
    },
  },
  computed: {
    chinese() {
      return i18n.global.t("base.chinese");
    },
    english() {
      return i18n.global.t("base.english");
    },
  },
};
</script>

<style>
a:hover {
  color: #aaaaaa;
}
a {
  color: #000000;
}
</style>
