import { permission } from '@/utils/permission';

/**
 * 权限
 * 传入的是对象：{
 *  list: [Array|String] 权限值
 *  type: [String] every, some 类型，every表示 && ，some表示 ||
 *  include: [Array|String|Object] 包含 预留 - 未写逻辑
 *  exclude: [Array|String|Object] 排除 预留 - 未写逻辑
 * }
 * @param {*} el
 * @param {*} binding
 * @param {*} vnode
 */
export const permit = (el, binding, vnode) => {
    const value = binding.value; /* 传入的参数，可以是数组、字符串、对象 */
	// console.log(el)
	// console.log(vnode)
    /* 没有权限则使用注释节点替换原节点 */
    if (!permission(value)) {
        const comment = document.createElement('div')
        vnode.elm = comment;
        vnode.text = ' ';
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment;
        }
        if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
			// el.parentNode.removeChild(el)
        }
    }
};
