<template>
  <SankeyTab
    v-model:currentTab="currentTab"
    :sankeyInfo="sankeyInfo"
  ></SankeyTab>
  <div
    v-if="showChart"
    :id="chartId"
    :style="{ width: '100%', height: chartHeight + 'px' }"
  ></div>
</template>

<script>
import { sankey1Chart } from "@/utils/chart.js";
import SankeyTab from "./SankeyTab.vue";
import _ from "lodash";
export default {
  props: {
    sankey: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      chartId: `chart${+new Date()}`,
      chartHeight: 300,
      sankeyInfo: {},
      currentTab: this.$route.query.chooseType || "",
    };
  },
  methods: {
    setChart(data) {
      const chartDom = document.querySelector(`#${this.chartId}`);
      if (!chartDom) {
        return;
      }
      let options = {
        chartDom: chartDom,
        data: data,
      };
      sankey1Chart(options);
    },
  },
  computed: {
    showChart() {
      if (this.sankeyInfo.links && this.sankeyInfo.links.length == 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    currentTab: {
      handler(val) {
        if (this.sankeyInfo.nodes) {
          this.sankeyInfo.nodes.forEach((node) => {
            node.type = val;
          });
        }
        setTimeout(() => {
          this.setChart(this.sankeyInfo);
        }, 0);
      },
    },
    sankey: {
      handler(val) {
        if (val) {
          this.sankeyInfo = _.cloneDeep(val);
          if (this.sankeyInfo.nodes && this.sankeyInfo.links) {
            this.sankeyInfo.nodes.forEach((node) => {
              node.type = this.currentTab;
            });
            const count = this.sankeyInfo.links.length;
            if (count >= 40) {
              this.chartHeight = 300 + count * 15;
            }
            setTimeout(() => {
              this.setChart(this.sankeyInfo);
            }, 0);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    SankeyTab,
  },
};
</script>

<style lang='scss'>
.mdi {
  color: #fff;
}
.tab-name {
  color: #fff;
  display: inline-block;
}
.rotate-180 {
  transform: rotate(180deg);
}
.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
