import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/utils/common.scss'
import '@/utils/default.scss'

import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import MessageAlert from "@/components/common/MessageAlert";
import DropZone from "@/components/widgets/dropZone";
import Pagination from "@/components/common/Pagination.vue";
import Empty from "@/components/common/Empty";
import Loading from "@/components/common/Loading.vue";
import SankeyChartShow from '@/views/components/SankeyChartShow.vue'

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import { permit } from '@/directives/permission';
import { permission } from '@/utils/permission';

const moment = require('moment');
moment.suppressDeprecationWarnings = true;

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

console.log(process.env)

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const app = createApp(App);
/* 注册全局组件 */
app.component('Layout', Layout);
app.component('PageHeader', PageHeader);
app.component('message-alert', MessageAlert);
app.component('Multiselect', Multiselect);
app.component('DropZone', DropZone);
app.component('Pagination', Pagination);
app.component('Empty', Empty);
app.component('Loading', Loading);
app.component('SankeyChartShow', SankeyChartShow)
// 屏蔽警告信息
app.config.warnHandler = () => null;

app.directive('permit', permit);
app.config.globalProperties.$permit = permission

app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside).mount('#app')