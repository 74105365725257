<template>
	<div
		@dragenter.prevent="toggleActive"
		@dragleave.prevent="toggleActive"
		@dragover.prevent
		@drop.prevent="toggleActive"
		:class="{ 'active-dropzone': active }"
		class="dropzone position-relative"
	>
		<div class="mb-0"><i class="display-4 text-muted ri-upload-cloud-2-fill"></i></div>

		<h5 class="mb-0">{{$t('upload.title')}}</h5>
		<div v-if="limit" class="text-muted fs-12">{{$t('upload.limit')}}{{ limit }}</div>
		<label :for="iptId" class="bg-light text-dark stretched-link">{{$t('upload.btn')}}</label>
		<input type="file" οnblur="this.value=''" :id="iptId" class="dropzoneFile btn btn-primary" :accept="accept" />
	</div>
</template>

<script>
import { ref } from 'vue';
export default {
	name: 'dropzone',
	props: {
		iptId: {
			type: String,
			default: 'dropzoneFile'
		},
		accept: {
			type: String,
			default: '*'
		},
		limit: {
			type: String,
			default: ''
		}
	},
	setup() {
		const active = ref(false);
		const toggleActive = () => {
			active.value = !active.value;
		};
		return { active, toggleActive };
	}
};
</script>
