export default {
	base: {
		search: '搜索',
		advance_search: '高级搜索',
		reset: '重置',
		min: '最小值',
		max: '最大值',
		to: '至',
		no_pictures: '暂无图片',
		add: '新增',
		add1: '+ 新增',
		to_view: '查看',
		edit: '编辑',
		delete: '删除',
		operation: '操作',
		save: '保存',
		confirm: '确认',
		done: '确定',
		cancel: '取消',
		success: '成功',
		fail: '失败',
		delete_alert_role_title: '删除失败！',
		delete_alert_role_info: '该角色下包含用户信息，请删除该角色下的所有用户或解绑该角色后才能删除！',
		delete_alert_title: '确定删除？',
		delete_alert_info: '此操作不可撤回！',
		delete_alert_completed: '已删除',
		add_success: '创建成功',
		edit_success: '编辑成功',
		search_id: '搜索',
		upload_file_limit: '只可上传jpg/png文件，且不超过50M',
		upload_picture_limit: '只可上传jpg/png文件，且不超过50M',
		upload_excel_limit: '只可上传Excel文件，且不超过50M',
		no_data: '暂无数据',
		hint_name: '名称不能为空！',
		none: '无',
		total: '合计',
		total_unit: '条',
		previous_page: '上一页',
		next_page: '下一页',
		jump_to: '跳转至',
		jump_to_unit: '页',
		empty: '暂无数据',
		loading: '加载中...',
		disabled: '不可用',
		enabled: '可用',
		request403: '没有登录或没有权限',
		grant_all_garment: '服装编辑',
		grant_all_garment_view: '服装仅查看',
		grant_all_fabric: '织物编辑',
		grant_all_fabric_view: '织物仅查看',
		grant_all_yarn: '纱线编辑',
		grant_all_yarn_view: '纱线仅查看',
		grant_all_fibre: '纤维编辑',
		grant_all_fibre_view: '纤维仅查看',
		grant_all_textile_bedware: '床上用品编辑',
		grant_all_textile_bedware_view: '床上用品仅查看',
		grant_all_textile_home_decor: '普通家用纺织品编辑',
		grant_all_textile_home_decor_view: '普通家用纺织品仅查看',
		grant_all_textile_industrial: '产业纺织品编辑',
		grant_all_textile_industrial_view: '产业纺织品编辑',
		grant_all_textile_kitchen: '厨卫用品编辑',
		grant_all_textile_kitchen_view: '厨卫用品仅查看',
		english: '英文',
		chinese: '中文',
		preview: '预览',
		is_public: '公开',
		user_info: '个人信息',
		user_center_name: "用户名",
		change_password: "修改密码",
		original_password: "原始密码",
		new_password: '新密码',
		confirm_password: '确认密码',
		confirm_password_info: '两次输入的密码不一致',
		change_success: '修改成功',
		filter_password: '新密码与原始密码一致,请重新修改',
		hint_code: '品目编码为必选项',
		No: '序号',
		resetFile: '上传新文件将会覆盖原有文件，是否继续?',
		show: '显示',
	},
	upload: {
		title: '将文件拖放到此处或单击上传',
		limit: '上传文件大小限制：',
		btn: '上传',
	},
	menu: {
		home: '主页',
		garment: '服装数据库',
		fabric: '织物数据库',
		yarn: '纱线数据库',
		fiber: '纤维数据库',
		hometextile: '家纺数据库',
		textile_bedware: '床上用品数据库',
		textile_home_decor: '普通家用纺织品数据库',
		textile_industrial: '产业纺织品数据库',
		textile_kitchen: '厨卫用品数据库',
		textile: '产业纺织品数据库',
		system_management: '系统管理',
		role: '角色',
		user: '用户',
		permission: '权限',
	},
	home: {
		title: 'FBD—纺织产业链大数据技术平台',
		supplier: "供应商",
		customers: "客户",
		products: "产品",
		market: "市场",
		inventory: "库存",
		quality: "常规基本质量指标",
		comfort: "手感/触感/穿着舒适",
		traceability: "可追溯性",
		circularity: "可循环性",
		GHG: "温室气体指数足迹",
		social: "社会影响",
	},
	attribute: {
		base: '基本信息',
		other: '其他信息',
		indicat: '可追溯性指标',
		value: '值',
		unit: '单位',
		id: 'ID',
		name: '名称',
		type: '类型',
		size: '尺码',
		size1: '尺寸',
		brand: '品牌',
		brand_name: '品牌名称',
		supplier: '供应商',
		cost: '成本',
		price: '价格',
		price_unit: '欧元/件',
		price_unit1: '欧元/吨',
		stock: '库存',
		stock_unit: '件',
		stock_unit1: '吨',
		style: '款式',
		fitting: '合身度',
		code: '品目编码',
		structure: '结构',
		safety: '安全',
		protection: '防护',
		features: '功能',
		others: '其他',
		composition: '组成结构',
		designer: '设计师',
		body_coverage_ratio: '人体覆盖率',
		manufacturer: '生产商',
		picture: '图片',
		water_contact_angle: '水接触角',
		water_contact_angle_unit: 'deg',
		percentage: '%',
		number: '编号',
		diameter: '直径',
		density: '密度',
		radius: '半径',
		phase_change_material_add_on_level: '相变材料添加量',
		latent_heat_of_fusion: '融化潜热',
		latent_heat_of_fusion_unit: 'cal/g',
		melting_point: '融化温度',
		thermal_conductivity_of_liquid_pcm: '液态相变材料热导率',
		thermal_conductivity_of_siquid_pcm: '固态相变材料热导率',
		heat_trnasfer_coefficient: '热传导系数',
		cm: 'cm',
		density_unit: 'g/m3',
		deg: 'deg',
		thermal_conductivity_unit: 'cal/s/cm/deg',
		heating_load: '热负荷',
		heating_load_unit: 'cal/s/cm3',
		switch_on_temperature: '开启温度',
		switch_off_temperature: '关闭温度',
		switch_off_temperature_unit: 'cal/s/cm3',
		water_vapor_permeability: '水蒸气透过性',
		thermal_resistance: '热阻',
		temperature: '温度',
		membrane_thickness: '膜厚度',
		membrane_type: '膜类型',
		membrane_location: '膜位置',
		tensile_properties: '拉伸性能',
		permeability: '透气性',
		tensile_stress: '拉伸应力',
		tensile_strain: '拉伸应变',
		pressure_range: '气压',
		flow_rate: '流速',
		feel_skin: '手感和皮肤舒适度',
		heat_simulation: '热湿舒适度-模拟设定',
		heat_file: '热度舒适度dat文件',
		version_2d: '2D版型',
		fitting_virtual: '虚拟合身度',
		fitting_virtual_link: '虚拟合身度超链接',
		body_virtual: '虚拟人体舒适度',
		body_virtual_link: '虚拟人体舒适度超链接',
		show_virtual: '虚拟展示',
		show_virtual_link: '虚拟展示超链接',
		last_time: '更新时间',
		description: '描述',
		status: '状态',
		upload_logo:'上传logo',
		role: '角色',
		role_name: '角色名称',
		parent_role: '父角色',
		user_name: '用户名称',
		password: '密码',
		organization: '组织',
		department: '部门',
		phone: '手机号码',
		email: '邮箱',
		create_time: '创建时间',
		just_fit: '合身',
		tight_fit: '紧身',
		loose_fit: '宽松',
		comfortable: '舒适性指标',
		quality: '质量指标',
		business: '商业指标',
		environment: '环境指标',
		shell: '外层',
		interlayer: '夹层',
		lining: '内衬',
		reference: '参考标准',
		indicators_related: '指标关联',
		indicator_name: '指标名称',
		please_set_hscode: '请先设置6位品目编码',
		indicator_related_confirm: '此操作将会修改该品目编码下的所有指标显示和参考标准，是否确认？',
		search_indicator_name: '搜索指标名称',
	},
	radar_chart: {
		feel: '手感',
		skin: '皮肤舒适度',
		skin_discomfort: '皮肤不适感',
		heat: '热湿舒适度',
		softness: '柔软度',
		stiff: '硬度',
		roughness: '粗糙度',
		smoothness: '光滑度',
		warmth: '暖和度',
		total: '综合',
		prickle: '刺痛度',
		comfort_value: '热舒适感',
		dampness_sensation: '湿感',
		thermal_sensation: '热感',
		average: '均值',
		footprint: '碳足迹',
		recyclable: '可循环指标',
		composition: '成分',
	},
	login: {
		title: '欢迎回来 ！',
		subtitle: '登录并继续下一步',
		username: '用户名',
		password: '密码',
		remember: '记住我',
		login: '登录',
		hint_username: '请输入用户名',
		hint_password: '请输入密码',
		logout_title: '确定退出吗？',
	},
	dashboard: {
		name: '主页',
		title: '主页',
	},
	garment: {
		name: '服装',
		title: '服装列表',
		add: '新增服装信息',
		edit: '编辑服装信息',
		detail: {
			title: '服装文件信息',
		},
		version: '服装版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
	},
	fabric: {
		name: '织物',
		title: '织物列表',
		add: '新增织物信息',
		edit: '编辑织物信息',
		comfortable_base: "舒适性基本信息",
		detail: {
			title: '织物文件信息',
			water: '织物水分传导功能',
			membrane: '膜性能',
			disguised: '相变资料',
			heat: '自加热',
			ftt: 'FTT织物触觉测试',
			liquid_water_conductivity: '织物液态水分传导性能',
			sst: '上层扩散速度',
			ssb: '底层扩散速度',
			r: '单向传输指数',
			ommc: '综合吸湿性能',
			top_water_contact_angle: '上层水接触角',
			bottom_water_contact_angle: '底层水接触角',
		},
		no_layers: '织物编号',
		id: '织物ID',
		structure: '织物结构',
		structure_layer: '织物结构层',
		color: '织物颜色',
		thickness: '织物厚度',
		thickness_unit: 'cm',
		weight: '织物重量',
		weight_unit: 'g/m2',
		density: '织物密度',
		density_unit: 'g/m3',
		tortuous: '织物曲隙率',
		porosity: '织物孔隙率',
		keyword_placeholder: 'ID/名称/类型/供应商',
		tips: '纺织品织物触感检测与评价方法多指标集成法FZ/T 01166-2022'
	},
	yarn: {
		name: '纱线',
		title: '纱线列表',
		add: '新增纱线信息',
		edit: '编辑纱线信息',
		detail: {
			title: '纱线文件信息',
		},
		id: '纱线ID',
		percentage: '纱线比例',
		percentage_unit: '%',
		type: '纱线类型',
		linear_density: '纱线线密度',
		linear_density_unit: 'tex',
		diameter: '纱线直径',
		diameter_unit: 'mm',
		twist: '纱线捻度',
		twist_unit: 'twist/meter',
		keyword_placeholder: 'ID/类型/供应商/品牌',
	},
	fiber: {
		name: '纤维',
		title: '纤维列表',
		add: '新增纤维信息',
		edit: '编辑纤维信息',
		detail: {
			title: '纤维文件信息',
		},
		id: '纤维ID',
		type: '纤维类型',
		percentage: '纤维比例',
		percentage_unit: '%',
		density: '纤维密度',
		density_unit: 'g/cm3',
		diameter: '纤维直径',
		diameter_unit: 'mm',
		thermal_conductivity: '纤维热导率',
		thermal_conductivity_unit: 'W/(m*k)',
		moisture_regain: '回潮率',
		moisture_regain1: '回潮率',
		water_contact_angle: '接触角',
		water_contact_angle_unit: 'deg',
		water_diffusion_coefficient: '水扩散系数',
		water_diffusion_coefficient_unit: 'm2/s',
		relative_humidity: '相对湿度',
		keyword_placeholder: 'ID/类型/供应商/品牌',
	},
	hometextile: {
		name: '家纺',
		title: '家纺列表',
		add: '新增家纺信息',
		edit: '编辑家纺信息',
		detail: {
			title: '家纺文件信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
	},
	textile: {
		name: '产业纺织品',
		title: '产业纺织品列表',
		add: '新增产业纺织品信息',
		edit: '编辑产业纺织品信息',
		detail: {
			title: '产业纺织品文件信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
	},
	textile_kitchen: {
		name: '厨卫用品',
		title: '厨卫用品列表',
		add: '新增厨卫用品',
		edit: '编辑厨卫用品',
		detail: {
			title: '厨卫用品信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
		parent: '产业纺织品数据库',
	},
	textile_bedware: {
		name: '床上用品',
		title: '床上用品列表',
		add: '新增床上用品信息',
		edit: '编辑床上用品信息',
		detail: {
			title: '床上用品信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
		parent: '家纺数据库',
	},
	textile_home_decor: {
		name: '普通家用纺织品',
		title: '普通家用纺织品列表',
		add: '新增普通家用纺织品信息',
		edit: '编辑普通家用纺织品信息',
		detail: {
			title: '普通家用纺织品信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
		parent: '家纺数据库',
	},
	textile_industrial: {
		name: '产业纺织品',
		title: '产业纺织品列表',
		add: '新增产业纺织品信息',
		edit: '编辑产业纺织品信息',
		detail: {
			title: '产业纺织品信息',
		},
		version: '产品版型',
		keyword_placeholder: 'ID/名称/类型/供应商/品牌',
		parent: '产业纺织品数据库',
	},
	role: {
		name: '角色',
		title: '角色列表',
		add: '新增角色信息',
		edit: '编辑角色信息',
		detail: {
			title: '角色信息',
		},
		keyword_placeholder: '角色名称',
		hint_role_name: '角色名称不能为空',
		emptyRole: "请先创建角色"
	},
	user: {
		name: '用户',
		title: '用户列表',
		add: '新增用户信息',
		edit: '编辑用户信息',
		detail: {
			title: '用户信息',
		},
		keyword_placeholder: '用户名称',
		hint_name: '名称不能为空',
		hint_user_name: '用户名称不能为空',
		hint_password: '密码不能为空',
		hint_role: '角色不能为空',
	},
	permission: {
		name: '权限',
		title: '权限',
		role: '角色列表',
		permission: '权限列表',
	},
	smooth: '光滑',
	rough: '粗糙',
	soft: '柔软',
	stiff: '硬挺',
	prickle: '刺痛',
	cool: '凉爽',
	warm: '温暖',
	comfortable: '舒适',
	uncomfortable: '不舒适',
	discomfort: '不舒适',
	dry: '干燥',
	damp: '潮湿',
	garmentProfile: '服装信息',
	fabricProfile: '织物信息',
	yarnProfile: '纱线信息',
	fibreProfile: '纤维信息',
	homeTextileProfile: '家纺信息',
	industrialTextileProfile: '产业纺织品信息',
}
