<template>
  <div class="sankeyTab mb-5">
    <div class="tab-top mb-3">
      <span
        :class="['btn-comp cursor', chooseComp && 'choose']"
        @click="handlerComponent()"
        >{{ $t("radar_chart.composition") }}</span
      >
    </div>
    <div class="tab-body">
      <template v-for="(tab, index) in tabList" :key="index">
        <b-badge
          :variant="currentChoose == index && tab.type"
          :class="[
            'badge-label rotate-180 py-2 me-3 cursor tab-label',
            index == 0 && 'ms-0',
            tab.disabled && 'disabled',
            currentChoose != index && 'not-choose',
          ]"
          @click="handlerChoose(index, tab)"
        >
          <i class="mdi mdi-circle-medium"></i>
          <span
            :class="['tab-name rotate-180', currentChoose == index && 'choose']"
            >{{ tab.name }}</span
          >
        </b-badge>
      </template>
    </div>
  </div>
  <div
    class="d-flex align-items-start flex-1"
    style="position: absolute; top: 20px; right: 0"
  >
    <template v-for="(legend, index) in legends" :key="index">
      <div class="d-flex align-items-center me-3" v-if="legend.show">
        <i :class="['le-icon fs-5', legend.icon]"></i>
        <span class="le-label fs-5 font-weight-medium me-2">
          {{ legend.label }}:
        </span>
        <span class="le-cont fs-6">{{ legend.name }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabList: [
        {
          name: this.$t("radar_chart.feel"),
          type: "primary",
          disabled: false,
          tab: "hand",
        },
        {
          name: this.$t("radar_chart.skin"),
          type: "secondary",
          disabled: false,
          tab: "skin",
        },
        {
          name: this.$t("radar_chart.comfort_value"),
          type: "danger",
          disabled: false,
          tab: "heat",
        },
        {
          name: this.$t("radar_chart.footprint"),
          type: "success",
          disabled: true,
          tab: "foot",
        },
        {
          name: this.$t("radar_chart.recyclable"),
          type: "warning",
          disabled: true,
          tab: "recycl",
        },
      ],
      currentChoose: null,
      chooseComp: true,
    };
  },
  props: {
    currentTab: {
      type: String,
      default: "",
    },
    sankeyInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["update:currentTab"],
  computed: {
    legends() {
      let legend = [
        {
          label: "GA",
          name: this.$t("garment.name"),
          icon: "ri-shirt-fill",
          type: "garment",
          show: false,
        },
        {
          label: "FA",
          name: this.$t("fabric.name"),
          icon: "ri-map-fill ",
          type: "fabric",
          show: false,
        },
        {
          label: "YA",
          name: this.$t("yarn.name"),
          icon: "ri-route-fill",
          type: "yarn",
          show: false,
        },
        {
          label: "FI",
          name: this.$t("fiber.name"),
          icon: "ri-reactjs-line",
          type: "fibre",
          show: false,
        },
        {
          label: "HO",
          name: this.$t("hometextile.name"),
          icon: "ri-hotel-bed-fill",
          type: "hometextile",
          show: false,
        },
        {
          label: "TE",
          name: this.$t("textile.name"),
          icon: "ri-scissors-cut-fill",
          type: "textile",
          show: false,
        },
      ];
      let nodeType = [];
      if (this.sankeyInfo && this.sankeyInfo.nodes) {
        this.sankeyInfo.nodes.forEach((node) => {
          if (!nodeType.includes(node.node_type)) {
            nodeType.push(node.node_type);
          }
        });
        legend.forEach((leg) => {
          nodeType.forEach((node) => {
            if (leg.type == node) {
              leg.show = true;
            }
          });
        });
      }
      return legend;
    },
  },
  watch: {
    currentTab: {
      handler(val) {
        this.chooseComp = false;
        switch (val) {
          case "hand":
            this.currentChoose = 0;
            break;
          case "skin":
            this.currentChoose = 1;
            break;
          case "heat":
            this.currentChoose = 2;
            break;
          case "foot":
            this.currentChoose = 3;
            break;
          case "recycl":
            this.currentChoose = 4;
            break;
          default:
            this.currentChoose = null;
            this.chooseComp = true;
            break;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handlerComponent() {
      if (this.currentTab) {
        this.chooseComp = true;
        this.currentChoose = null;
        this.$emit("update:currentTab", "");
      }
    },
    handlerChoose(index, tabInfo) {
      if (!tabInfo.disabled) {
        if (this.currentChoose == index) {
          this.currentChoose = null;
          this.$emit("update:currentTab", "");
        } else {
          this.currentChoose = index;
          this.$emit("update:currentTab", tabInfo.tab);
        }
      }
    },
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.mdi {
  color: #fff;
}
.btn-comp {
  background-color: #e9e9e9;
  border-radius: 5px;
  color: #000;
  padding: 5px 20px;
  &.choose {
    background-color: #299cdb;
  }
}
.tab-name {
  color: #000;
  font-weight: 400;
  display: inline-block;
}
.rotate-180 {
  transform: rotate(180deg);
}
.tab-label {
  background-color: #e9e9e9;
}
.not-choose {
  &.badge-label:before {
    border-right-color: #e9e9e9;
  }
}
.choose {
  color: #fff;
}
.disabled {
  opacity: 1;
  cursor: not-allowed;
}

.le-icon {
  color: #56c3fa;
}

.le-cont {
  color: #495057;
}
</style>
