import _ from 'lodash';
import store from "@/state/store";

const TYPES = ['every', 'some'];
const MATCH = ['startsWith', 'endsWith', 'includes'];

/**
 * 字符串转换为数组
 * @param {string|array} str
 * @return {array}
 */
function str2List (str) {
    if (str instanceof Array) {
        return [...str];
    }

    /* 字符串格式化成数组 */
    if (typeof str === 'string') {
        return [str];
    }

    return [];
}

function getPermissionList() {
	let storeData = store.state.login.menuList || sessionStorage.getItem('set_menu_list');
	let permissions = ''
	if (storeData) {
		permissions = JSON.parse(storeData).permissions
		permissions = _.join(permissions, ',')
	}
	return permissions
}

/**
 * 权限
 * @param {*} value
 * @return {bool} 是否存在权限
 */
export const permission = (value) => {
    let permitList = str2List(value);
    let type = TYPES[0]; /* &&, || */
    let match = ''; /* startsWith, endsWith, includes */

    /**
     * 传入的是对象
     * {
     *  list: [Array|String] 权限值
     *  type: [String] every, some 类型，every表示 && ，some表示 ||
     *  match: [String] 用于匹配每一项permission的值 startsWith, endsWith, includes
     *  include: [Array|String|Object] 包含 预留 - 未写逻辑
     *  exclude: [Array|String|Object] 排除 预留 - 未写逻辑
     * }
     */
    if ((value instanceof Object) && !(value instanceof Array)) {
        permitList = str2List(value.list);
        type = TYPES.includes(value.type) ? value.type : TYPES[0];
        match = MATCH.includes(value.match) ? value.match : '';
    }

    if (permitList.length === 0) {
        return false;
    }

    const permissionList = getPermissionList();

    return permitList[type](perm => {
        if (!match) {
            return permissionList.includes(perm);
        }

        return permissionList.some(p => p[match](perm));
    });
};

// export const isAdmin = () => {
// 	let storeData = store.state.login.menuList || sessionStorage.getItem('set_menu_list');
// 	if (storeData) {
// 		storeData = JSON.parse(storeData)
// 		console.log(storeData)
// 		if (storeData.is_admin) {
// 			return true
// 		}
// 	}
// 	console.log('false')
// 	return false
// }

export function isAdmin() {
	let storeData = store.state.login.menuList || sessionStorage.getItem('set_menu_list');
	if (storeData) {
		storeData = JSON.parse(storeData)
		if (storeData.is_admin) {
			return true
		}
	}
	return false
}

export function getLoginData() {
	let storeData = store.state.login.menuList || sessionStorage.getItem('set_menu_list');
	if (storeData) {
		storeData = JSON.parse(storeData)
		return storeData
	}
	return null
}