import * as echarts from 'echarts';
import i18n from "@/i18n"

/* 圆形进度条 */
const circleEcharts = (options) => {
	let {
		chartDom,
		dataList,
		color
	} = options;
	let title = options.title || {}
	let myChart = chartDom && echarts.init(chartDom);
	let option = {
		color: color,
		title: [{
			text: dataList[0].value + '%',
			subtext: title.subtext || '',
			top: 'center',
			left: 'center',
			textStyle: {
				fontSize: title.fontSize || '12px',
				color: '#000', //圆环中心文字字体颜色
			}
		}],
		series: [{
			type: 'pie',
			radius: ['75%', '85%'], //控制圆环大小
			center: ['50%', '50%'], //控制圆环位置
			data: dataList,
			hoverAnimation: false,
			itemStyle: {
				normal: {
					borderWidth: 0
				}
			},
			labelLine: {
				normal: {
					length: 0,
					length2: 0,
					lineStyle: {
						color: '#e6e6e6'
					}
				}
			}
		}]
	};
	myChart && option && myChart.setOption(option)
}

/* 半圆型仪表盘 */
const halfRingChart = (options) => {
	let {
		chartDom,
		dataList,
		color
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	let chartPro = 0;
	dataList.substitute && dataList.substitute.forEach(sub => {
		chartPro += sub.cumulative_weight
	})
	let option = {
		color: color,
		title: [{
			text: dataList.process_alt + '%',
			left: 'center',
			top: '50%',
			textStyle: {
				color: '#000',
				fontSize: 13,
			}
		}, {
			text: `${parseFloat((dataList.cumulative_weight + chartPro).toFixed(3))} / ${dataList.dedatad_weight}(Ton)`,
			left: 'center',
			top: '65%',
			textStyle: {
				color: 'rgba(33, 37, 41, 0.64)',
				fontSize: 10
			}
		},
		{
			text: dataList.material_name || dataList.product_name,
			left: 'center',
			top: '78%',
			textStyle: {
				color: 'rgba(33, 37, 41, 0.64)',
				fontSize: 12
			}
		}
		],
		angleAxis: {
			show: false,
			// 后面的180表示圆环的跨越的角度， 如max设置为100*360/270,startAngle设置为-135
			max: 100 * 360 / 180,
			type: 'value',
			startAngle: 180,
			splitLine: {
				show: false
			}
		},
		// 修改环形的宽度
		barMaxWidth: 5,
		radiusAxis: {
			show: false,
			type: 'category'
		},
		polar: {
			// 设置环形的位置
			center: ['50%', '66%'],
			// 设置环形大小
			radius: '120%'
		},
		series: [{
			type: 'bar',
			data: [dataList.process_alt > 100 ? 100 : dataList.process_alt],
			barGap: '-100%',
			coordinateSystem: 'polar',
			z: 3,
		},
		{
			type: 'bar',
			data: [100],
			barGap: '-100%',
			coordinateSystem: 'polar',
			z: 1,
		}
		]
	}
	myChart && option && myChart.setOption(option)
}

/* 柱状图 */
const barChart = (options) => {
	let {
		chartDom,
		xAxisData,
		yAxisData
	} = options;
	let myChart = chartDom && echarts.init(chartDom);

	let option = {
		grid: {
			left: '0%', //默认10%
			right: '0%', //默认10%
			bottom: '2%', //默认60
			containLabel: true
			//grid区域是否包含坐标轴的刻度标签
		},
		title: [{
			text: 'Unit:(kg)',
			left: "0",
			top: "-5",
			textStyle: {
				fontSize: 12,
				color: '#000', //圆环中心文字字体颜色
			}
		}],
		tooltip: {
			trigger: 'axis',
			axisPointer: { // 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			},
			formatter: function (params) {
				var res;
				var name;
				if (params && params[0].axisValue) {
					switch (params[0].axisValue) {
						case 'PW':
							name = 'Pre-Wash'
							break;
						case 'HC':
							name = 'Hydrocyclone'
							break;
						case 'SP':
							name = 'Screw Press '
							break;
						case 'WT':
							name = 'Water Treatment'
							break;
						case 'PRG':
							name = 'Purge'
							break;
					}
				}
				res = `
                    <div>${name}</div>
                    <div><span>${params[0].marker}</span>${params[0].data}</div>
                    `;
				return res
			}
		},
		xAxis: {
			type: 'category',
			data: xAxisData,
			axisLine: {
				show: true, // 隐藏x轴轴线
			},
			axisTick: {
				show: true, // 隐藏坐标轴的刻度线
				alignWithLabel: true, // 图形于标题居中
				outSide: true, // 刻度线是指向内部还是外部
				length: 3, // x轴上指向标题刻度线的长度
			},
			axisLabel: {
				interval: 0,
				formatter: function (params) {
					var newParamsName = "";
					var paramsNameNumber = params.length;
					var provideNumber = 5; // 一行显示几个字 然后就超过字数就会自动换行
					var rowNumber = Math.ceil(paramsNameNumber / provideNumber);

					if (paramsNameNumber > provideNumber) {
						for (var p = 0; p < rowNumber; p++) {
							var tempStr = "";
							var start = p * provideNumber;
							var end = start + provideNumber;
							if (p == rowNumber - 1) {
								tempStr = params.substring(start, paramsNameNumber);
							} else {
								tempStr = params.substring(start, end) + "\n";
							}
							newParamsName += tempStr;
						}
					} else {
						newParamsName = params;
					}
					return newParamsName;
				},
				textStyle: {
					fontSize: 10
				},
			},
		},
		yAxis: {
			type: 'value',
		},
		series: [{
			data: yAxisData,
			type: 'bar'
		}]
	};

	myChart && option && myChart.setOption(option);
}

const maxNumber = 1

/* 雷达图 */
const radarChart = (options,  platform='pc', type='hand') => {
	let {
		chartDom,
		data,
		color,
		name,
		ave
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	// 手感雷达图坐标指示名称
	let indicatorNames = [
		i18n.global.t('soft'),
		i18n.global.t('smooth'),
		i18n.global.t('warm'),
		i18n.global.t('radar_chart.total'),
	]
	if (type === 'skin') {
		indicatorNames = [
			i18n.global.t('stiff'),
			i18n.global.t('prickle'),
			i18n.global.t('cool'),
			i18n.global.t('discomfort'),
		]
	}
	// 雷达图标题
	let title = i18n.global.t('radar_chart.feel')
	if (type === 'skin') {
		title = i18n.global.t('radar_chart.skin_discomfort')
	}

	const indicator = [{
		name: indicatorNames[0],
		min: 0,
		max: maxNumber,
		// 坐标轴刻度标签的相关设置
		axisLabel: {
			show: true,
			formatter: function (value, index) {
				return index > 0 ? value : '';
			}
		}
	},
	{
		name: indicatorNames[1],
		min: 0,
		max: maxNumber,
		axisLabel: {
			show: true,
			formatter: function (value, index) {
				return index > 0 ? value : '';
			}
		}
	},
	{
		name: indicatorNames[2],
		min: 0,
		max: maxNumber,
		axisLabel: {
			show: true,
			// formatter: function (value, index) {
			// 	return index > 0 ? value : '';
			// }
		}
	},
	{
		name: indicatorNames[3],
		min: 0,
		max: maxNumber,
		axisLabel: {
			show: true,
			formatter: function (value, index) {
				return index > 0 ? value : '';
			}
		}
	}
	];
	let option = {
		title: {
			text: title,
			left: '10',
			top: '3',
			textStyle: {
				fontSize: 12,
				color: '#000',
				fontWeight: 'bold'
			}
		},
		backgroundColor: 'transparent',
		tooltip: {
			trigger: 'item',
		},
		legend: {
			orient: platform === 'pc' ? 'horizontal' : 'horizontal',
			icon: 'circle', //图例形状
			data: [name, i18n.global.t('radar_chart.average')],
			formatter: function (name) {
				let lineLength = platform === 'pc' ? 30 : 20
				if (name.length <= lineLength) return name
				let newName = ''
				let i = 0
				while (i < name.length) {
					newName += name.slice(i, i + lineLength) + '\n'
					i += lineLength
				}
				return newName
			},
			bottom: '0',
			left: 'center',
			// top: platform === 'pc' ? 35 : 10,
			// right: platform === 'pc' ? 35 : 10,
			itemWidth: 10, // 图例标记的图形宽度。[ default: 25 ]
			itemHeight: 10, // 图例标记的图形高度。[ default: 14 ]
			itemGap: 5, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
			textStyle: {
				fontSize: 12,
				color: '#A8A8A8',
			},
		},
		color: [color, '#000'],
		radar: {
			name: {
				textStyle: {
					color: '#A8A8A8',
					fontSize: 12,
				},
				//指示器文字换行 start
				formatter: function (text) {
					// var strlength = text.length;
					// if (strlength % 4 != 0) {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n'
					// 	})
					// } else {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n'
					// 	});
					// 	strlength = text.length;
					// 	text = text.substring(0, strlength - 1);
					// }
					text = text.replace(' ', '\n')
					return text
				},
			},
			splitNumber: 5,
			indicator: indicator,
			center: ['50%', '50%'], // 重新定位雷达图的中心位置
			radius: platform == 'pc' ?'65%' : '55%'  // 调整雷达图大小
		},
		series: [{
			type: 'radar',
			symbolSize: 0,
			data: [{
				value: data,
				name: name,
				label: {
					normal: {
						show: false,
						formatter: (params) => {
							return params.value;
						},
						color: '#000',
					},
				},
				areaStyle: {
					normal: {
						color: color,
					},
				},
				lineStyle: {
					normal: {
						lineStyle: {
							width: 1,
						},
						opacity: 1,
					},
				},
				itemStyle: { // 折线拐点标志的样式。
					normal: { // 普通状态时的样式
						lineStyle: {
							width: 1,
						},
					},
					emphasis: { // 高亮时的样式
						lineStyle: {
							width: 5,
						},
						opacity: 0,
					},
				},
			},],
		},
		/* 均值 */
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				value: ave,
				name: i18n.global.t('radar_chart.average'),
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#919191',
				},
				lineStyle: {
					type: 'solid',
					lineStyle: {
						width: 1,
					},
					color: '#000',
					opacity: 1,
				},
				itemStyle: { // 折线拐点标志的样式。
					lineStyle: {
						width: 1,
					},
					emphasis: { // 高亮时的样式
						lineStyle: {
							width: 5,
						},
						opacity: 0,
					},
				},
			},],
		}
		],
	}
	myChart && option && myChart.setOption(option);
}

const radar1Chart = (options, platform='pc') => {
	let {
		chartDom,
		data,
		color,
		name,
		ave
	} = options;
	let myChart = chartDom && echarts.init(chartDom);

	const indicator = [{
		name: i18n.global.t('radar_chart.comfort_value'),
		max: maxNumber,
		axisLabel: {
			show: true,
			formatter: function (value, index) {
				return index > 0 ? value : '';
			}
		},
	},
	{
		name: i18n.global.t('radar_chart.dampness_sensation'),
		max: maxNumber,
		axisLabel: {
			show: true,
			formatter: function (value, index) {
				return index > 0 ? value : '';
			}
		},
	},
	{
		name: i18n.global.t('radar_chart.thermal_sensation'),
		max: maxNumber,
		axisLabel: {
			show: true,
			// formatter: function (value, index) {
			// 	return index > 0 ? value : '';
			// }
		},
	},
		// {
		// 	name: i18n.global.t('radar_chart.total'),
		// 	max: maxNumber,
		// }
	];
	let option = {
		backgroundColor: 'transparent',
		tooltip: {
			trigger: 'item',
			confine: true,
		},
		legend: {
			orient: 'vertical',
			icon: 'circle', //图例形状
			data: [name, i18n.global.t('radar_chart.average')],
			top: platform === 'pc' ? 35 : 10,
			right: platform === 'pc' ? 40 : 10,
			itemWidth: 10, // 图例标记的图形宽度。[ default: 25 ]
			itemHeight: 10, // 图例标记的图形高度。[ default: 14 ]
			itemGap: 10, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
			textStyle: {
				fontSize: 12,
				color: '#A8A8A8',
			},
		},
		color: [color, '#000'],
		radar: {
			name: {
				textStyle: {
					color: '#A8A8A8',
					fontSize: 12,
				},
				//指示器文字换行 start
				formatter: function (text) {
					// var strlength = text.length;
					// if (strlength % 4 != 0) {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n'
					// 	})
					// } else {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n'
					// 	});
					// 	strlength = text.length;
					// 	text = text.substring(0, strlength - 1);
					// }
					text = text.replace(' ', '\n')
					return text
				},
			},
			//指示器文字换行 end
			splitNumber: 5,
			indicator: indicator,
		},
		series: [{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data,
				name: name,
				label: {
					normal: {
						show: false,
						formatter: (params) => {
							return params.value;
						},
						color: '#000',
					},
				},
				areaStyle: {
					normal: {
						color: color,
					},
				},
				lineStyle: {
					normal: {
						lineStyle: {
							width: 1,
						},
						opacity: 1,
					},
				},
				itemStyle: { // 折线拐点标志的样式。
					normal: { // 普通状态时的样式
						lineStyle: {
							width: 1,
						},
					},
					emphasis: { // 高亮时的样式
						lineStyle: {
							width: 5,
						},
						opacity: 0,
					},
				},
			},],
		},
		/* 均值 */
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: ave,
				name: i18n.global.t('radar_chart.average'),
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#919191',
				},
				lineStyle: {
					type: 'solid',
					lineStyle: {
						width: 1,
					},
					color: '#000',
					opacity: 1,
				},
				itemStyle: { // 折线拐点标志的样式。
					lineStyle: {
						width: 1,
					},
					emphasis: { // 高亮时的样式
						lineStyle: {
							width: 5,
						},
						opacity: 0,
					},
				},
			},],
		}
		],
	}
	myChart && option && myChart.setOption(option);
}

/* 桑基图 */
const sankeyChart = (options) => {
	let {
		chartDom
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	let datalist = [{
		name: "服装"
	},
	{
		name: "织物1"
	},
	{
		name: "织物2"
	},
	{
		name: "纱线1"
	},
	{
		name: "纤维1"
	},
	{
		name: "纱线2"
	},
	{
		name: "纤维2"
	},
	{
		name: "纱线11"
	},
	{
		name: "纱线22"
	},
	{
		name: "纱线33"
	},
	{
		name: "纤维11"
	},
	{
		name: "纤维22"
	},
	{
		name: "纤维33"
	},
	];
	let linksData = [{
		source: "服装",
		target: "织物1",
		value: 1044305
	},
	{
		source: "服装",
		target: "织物2",
		value: 651527
	},
	{
		source: "织物1",
		target: "纱线1",
		value: 651527
	},
	{
		source: "织物1",
		target: "纤维1",
		value: 486710
	},
	{
		source: "织物2",
		target: "纱线2",
		value: 212670
	},
	{
		source: "织物2",
		target: "纤维2",
		value: 210400
	}, {
		source: "纱线1",
		target: "纱线11",
		value: 645246
	},
	{
		source: "纱线1",
		target: "纱线22",
		value: 513275
	},
	{
		source: "纱线1",
		target: "纱线33",
		value: 282986
	},
	{
		source: "纤维2",
		target: "纤维11",
		value: 118655
	},
	{
		source: "纤维2",
		target: "纤维22",
		value: 105989
	},
	{
		source: "纤维2",
		target: "纤维33",
		value: 95100
	},
	];
	let option = {
		series: [{
			type: "sankey",
			top: '10%',
			nodeGap: 15,
			nodeWidth: 25,
			focusNodeAdjacency: "allEdges",
			data: datalist,
			links: linksData,
			label: {
				normal: {
					color: "#000",
					fontSize: 12,
				}
			},
			lineStyle: {
				normal: {
					opacity: 0.4,
					color: "source",
					curveness: 0.5
				}
			},
			itemStyle: {
				normal: {
					borderWidth: 1,
					borderColor: "transparent"
				}
			}
		}]
	};
	myChart && option && myChart.setOption(option);
}

import router from '../router/index.js'

const sankey1Chart = (options) => {
	let {
		chartDom,
		data
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	let datalist = [];
	let linksData = [];
	datalist = data.nodes
	linksData = data.links
	let option = {
		// tooltip: {
		// 	trigger: 'item',
		// 	formatter: "{c}",
		// },
		series: [{
			type: "sankey",
			top: '5%',
			nodeGap: 15,
			nodeWidth: 25,
			draggable: false,
			emphasis: { focus: 'adjacency' },
			data: datalist,
			links: linksData,
			label: {
				normal: {
					color: "#000",
					fontSize: 12,
				}
			},
			lineStyle: {
				normal: {
					opacity: 0.4,
					color: "source",
					curveness: 0.5
				}
			},
			itemStyle: {
				normal: {
					borderWidth: 1,
					borderColor: "transparent"
				}
			},
		}]
	};
	myChart && option && myChart.setOption(option);

	myChart.on('click', function (params) {
		if (params.dataType == 'node') {
			const data = params.data
			const node_type = data.node_type
			// console.log(data)
			const chooseType = data.type
			const target_id = data.unique_id
			if (node_type == 'fabric') {
				router.push(`/fabric`);
				let detail_tab = 0;
				let current_tab = 0;
				if (chooseType == 'hand') {
					detail_tab = 5;
				} else if (chooseType == 'skin') {
					detail_tab = 6;
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'fabric_detail')
			} else if (node_type == 'garment') {
				router.push(`/garment`);
				let detail_tab = 0;
				let current_tab = 1;
				if (chooseType == 'heat') {
					detail_tab = 1
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'garment_detail')
			} else if (node_type == 'textile_bedware') {
				router.push(`/textile_bedware`);
				let detail_tab = 0;
				let current_tab = 1;
				if (chooseType == 'heat') {
					detail_tab = 1
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'textile_detail', 'textile_bedware')
			} else if (node_type == 'textile_home_decor') {
				router.push(`/textile_home_decor`);
				let detail_tab = 0;
				let current_tab = 1;
				if (chooseType == 'heat') {
					detail_tab = 1
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'textile_detail', 'textile_home_decor')
			} else if (node_type == 'textile_industrial') {
				router.push(`/textile_industrial`);
				let detail_tab = 0;
				let current_tab = 1;
				if (chooseType == 'heat') {
					detail_tab = 1
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'textile_detail', 'textile_industrial')
			} else if (node_type == 'textile_kitchen') {
				router.push(`/textile_kitchen`);
				let detail_tab = 0;
				let current_tab = 1;
				if (chooseType == 'heat') {
					detail_tab = 1
				}
				jumpToDetail(chooseType, current_tab, detail_tab, target_id, 'textile_detail', 'textile_kitchen')
			} else if (node_type == 'yarn') {
				router.push(`/yarn_detail/?id=${target_id}&type=view`);
			} else if ((node_type == 'fibre' || node_type == 'fiber')) {
				router.push(`/fiber_detail/?id=${target_id}&type=view`);
			}
		}
	});
	return myChart

	function jumpToDetail(chooseType, current_tab, detail_tab, target_id, to, dataType='') {
		if (chooseType) {
			setTimeout(() => {
				router.push(`/${to}/?id=${target_id}&type=view&current_tab=${current_tab}&detail_tab=${detail_tab}&chooseType=${chooseType}&data_type=${dataType}`);
			}, 0)
		} else {
			setTimeout(() => {
				router.push(`/${to}/?id=${target_id}&type=view&data_type=${dataType}`);
			}, 0)
		}
	}
}

/* 可拖拽雷达图 */

const dragRadarChart = (options, calback) => {
	let {
		chartDom,
		color,
		chartData,
		type
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	const symbolSize = 10;
	/* point 上｜左｜下｜右 */
	const data = [
		[0, 10],
		[-10, 0],
		[0, -10],
		[10, 0]
	];
	const data1 = chartData
	const indicator = [{
		name: i18n.global.t('radar_chart.softness'),
		max: 10,
	},
	{
		name: i18n.global.t('radar_chart.roughness'),
		max: 10
	},
	{
		name: i18n.global.t('radar_chart.warmth'),
		max: 10
	},
	{
		name: i18n.global.t('radar_chart.total'),
		max: 10
	}
	];
	let option = {
		tooltip: {
			triggerOn: 'none',
			formatter: function (params) {
				let key = null;
				switch (params.dataIndex) {
					case 0:
						key = i18n.global.t('radar_chart.softness')
						break
					case 1:
						key = i18n.global.t('radar_chart.roughness')
						break
					case 2:
						key = i18n.global.t('radar_chart.warmth')
						break
					case 3:
						key = i18n.global.t('radar_chart.total')
						break
				}
				return `${key}`
			}
		},
		grid: {
			top: '12%',
			bottom: '12%',
			right: '28%',
			left: '28%'
		},
		xAxis: {
			min: -10,
			max: 10,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		yAxis: {
			min: -10,
			max: 10,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		radar: {
			axisName: {
				color: '#A8A8A8',
				fontSize: 12,
				//指示器文字换行 start
				formatter: function (text) {
					var strlength = text.length;
					if (strlength % 4 != 0) {
						text = text.replace(/\S{4}/g, function (match) {
							return match + '\n';
						});
					} else {
						text = text.replace(/\S{4}/g, function (match) {
							return match + '\n';
						});
						strlength = text.length;
						text = text.substring(0, strlength - 1);
					}
					return text;
				}
				//指示器文字换行 end
			},

			indicator: indicator
		},
		color: color,
		series: [{
			type: 'line',
			smooth: false,
			lineStyle: {
				width: 0
			},
			symbolSize: 0,
			data: data
		},
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data1,
				label: {
					show: true,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				areaStyle: {
					color: color,
					opacity: 0.2
				},
				lineStyle: {
					width: 1,
					opacity: 0.5
				}
			}]
		}
		]
	};
	setTimeout(function () {
		// Add shadow circles (which is not visible) to enable drag.
		myChart.setOption({
			graphic: data.map(function (item, dataIndex) {
				return {
					type: 'circle',
					position: myChart.convertToPixel('grid', item),
					shape: {
						cx: 0,
						cy: 0,
						r: symbolSize / 2
					},
					style: {
						fill: "#fff",
						stroke: color
					},
					invisible: false,
					draggable: true,
					ondrag: function (e) {
						onPointDragging(dataIndex, [this.x, this.y], e);
					},
					ondragend: () => {
						/* 结束操作后｜回调数据 */
						calback && calback({
							type: type,
							data: data1
						})
					},
					onmousemove: function () {
						showTooltip(dataIndex);
					},
					onmouseout: function () {
						hideTooltip(dataIndex);
					},
					z: 100
				};
			})
		});
	}, 0);

	function showTooltip(dataIndex) {
		myChart.dispatchAction({
			type: 'showTip',
			seriesIndex: 0,
			dataIndex: dataIndex
		});
	}

	function hideTooltip(dataIndex) {
		myChart.dispatchAction({
			type: 'hideTip',
			seriesIndex: 0,
			dataIndex: dataIndex
		});
	}

	function onPointDragging(dataIndex, pos, e) {
		let positionX = myChart.convertFromPixel('grid', pos)[0]
		let positionY = myChart.convertFromPixel('grid', pos)[1]
		/* Y轴操作 */
		if (dataIndex == 0 || dataIndex == 2) {
			if (positionY >= -10 && positionY <= 10) {
				data[dataIndex][1] = positionY
				if (dataIndex == 0) {
					data1[dataIndex] = Number(positionY.toFixed(1))
				} else {
					data1[dataIndex] = Number(-(positionY.toFixed(1)))
				}
			} else if (positionY == -10) {
				data[dataIndex][1] = -10
				data1[dataIndex] = 10
			} else if (positionY == 10) {
				data[dataIndex][1] = 10
				data1[dataIndex] = 10
			}
			/* 控制移动y轴的范围 */
			e.target.x = 200
			if (e.target.y < 28) {
				e.target.y = 28
			} else if (e.target.y >= 28 && e.target.y >= 202) {
				e.target.y = 203
			}
		}
		/* X轴操作 */
		if (dataIndex == 1 || dataIndex == 3) {
			if (positionX >= -10 && positionX <= 10) {
				data[dataIndex][0] = positionX
				if (dataIndex == 3) {
					data1[dataIndex] = Number(positionX.toFixed(1))
				} else {
					data1[dataIndex] = Number(-(positionX.toFixed(1)))
				}
			} else if (positionX == -10) {
				data[dataIndex][0] = -10
				data1[dataIndex] = 10
			} else if (positionX == 10) {
				data[dataIndex][0] = 10
				data1[dataIndex] = 10
			}
			/* 控制移动x轴的范围 */
			e.target.y = 115
			if (e.target.x < 113) {
				e.target.x = 112
			} else if (e.target.x >= 113 && e.target.x >= 288) {
				e.target.x = 289
			}

		}

		// Update data
		myChart.setOption({
			series: [{
				type: 'line',
				smooth: false,
				lineStyle: {
					width: 0
				},
				symbolSize: 0,
				data: data
			},
			{
				type: 'radar',
				symbolSize: 0,
				data: [{
					// TODO:
					value: data1,
					label: {
						show: true,
						formatter: (params) => {
							return params.value;
						},
						color: '#000'
					},
					areaStyle: {
						color: color,
						opacity: 0.2
					},
					lineStyle: {
						width: 1,
						opacity: 0.5
					}
				}]
			}
			]
		});
	}

	myChart && option && myChart.setOption(option);
}

export {
	circleEcharts,
	halfRingChart,
	barChart,
	radarChart,
	radar1Chart,
	sankeyChart,
	sankey1Chart,
	dragRadarChart
}
