<template>
    <template v-if="flag == 0">
        <div class="empty mb" :style="position">
            <i class="las la-cloud-meatball"></i>
            <p class="text-center">{{ content }}</p>
        </div>
    </template>
    <template v-else-if="flag == 1">
        <div class="empty">
            <i class="las la-cloud-meatball"></i>
            <p class="text-center">{{ content }}</p>
        </div>
    </template>
    <template v-else-if="flag == 2">
        <div class="empty">
            <p class="text-center">{{ content }}</p>
        </div>
    </template>
</template>

<script>
    import i18n from "@/i18n"
    export default {
        data() {
            return {};
        },
        props: {
            content: {
                type: String,
                default: i18n.global.t('base.empty'),
            },
            position: {
                type: Object,
                default: () => {},
            },
            flag: {
                type: Number,
                default: 0
            }
        },
        methods: {},
        components: {},
    };
</script>

<style scoped lang="scss">
    .empty {
        font-size: 1rem;
        font-weight: 500;
        color: rgba(33, 37, 41, 0.64);
        //position: absolute;
        //top: 50%;
        //left: 50%;
        //transform: translate(-50%, -50%);
        //margin-bottom: 2rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
            font-size: 5rem;
        }
    }
    .mb {
      margin-bottom: 2rem;
    }
</style>
